import usdt from "./assets/usdt.png";
import bnb from "./assets/bnb.png";
import tokenImage from "./assets/token.png";

const info = {
  contractAddress: "0x8ca1e558A7118AC19c4E09f8BF3D8b739DbcEDee",
  usdtAddress: "0x55d398326f99059fF775485246999027B3197955",
  tokenDecimal: 18,
  refPercent: 10,
  referral: false,
  tokens: [
    {
      name: "Tether USD",
      symbol: "USDT",
      image: usdt,
      baseToken: true,
      address: "0x55d398326f99059fF775485246999027B3197955",
    },
    {
      name: "Binance Coin",
      symbol: "BNB",
      image: bnb,
      native: true,
      address: "0x0000000000000000000000000000000000000000",
    },
  ],
  buyToken: {
    name: "VIOLETAI",
    symbol: "VIOLETAI",
    image: tokenImage,
  },
};

export default info;

import React, { useEffect, useState } from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import Form from "react-bootstrap/Form";
import Input from "./components/Input";
import info from "./info.js";
import Currency from "./components/Currency";
import { Card } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTwitter,
  faTelegram,
  faInstagram,
  faBars,
} from "@fortawesome/free-brands-svg-icons"; // Added faBars from free-solid-svg-icons

import {
  useAccount,
  useWaitForTransactionReceipt,
  useWriteContract,
} from "wagmi";
import { ConnectKitButton } from "connectkit";
import {
  addDecimal,
  getAllowance,
  getHardcap,
  getRate,
  getRefPercent,
  getTokenAmount,
  getTokensSold,
  getUsersBought,
  getWeiRaised,
  removeDecimal,
} from "./hooks/wagmiHooks";
import { writeContract, waitForTransactionReceipt } from "@wagmi/core";
import { erc20Abi } from "viem";
import { config } from "./App";
import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { presaleAbi } from "./abi/presaleAbi";
import { BigNumber } from "bignumber.js";
import useWindowHeight from "./useWindowHeight";

function Presale() {
  const [barwidth, setBarwidth] = useState(0);
  const [chosenPayment, setchosenPayment] = useState(info.tokens[0]);
  const [firstinput, setfirstinput] = useState("");
  const [secondinput, setsecondinput] = useState(0);
  const [totalRaised, settotalRaised] = useState(0);
  const { address, isConnected } = useAccount();
  const [usersBuy, setusersBuy] = useState(0);
  const [approveNeeded, setApproveNeeded] = useState(true);
  const [usdtPrice, setusdtPrice] = useState(0);
  const [tokensSold, settokensSold] = useState(0);
  const [hardcap, sethardcap] = useState(0);
  const height = useWindowHeight();

  const { data: hash, writeContract, error: writeError } = useWriteContract();

  const [showMobileMenu, setShowMobileMenu] = useState(false);

  useEffect(() => {
    if (writeError) {
      console.log(writeError);
      toast.error("Some Error Happened");
    }
  }, [writeError]);

  const { isLoading: isConfirming, isSuccess: isConfirmed } =
    useWaitForTransactionReceipt({
      hash,
    });

  useEffect(() => {
    const getApproveInfo = async () => {
      if (address && isConnected && firstinput && !chosenPayment.native) {
        const allowance = await getAllowance(address, chosenPayment.address);
        if (allowance >= Number(firstinput)) {
          setApproveNeeded(false);
        } else {
          setApproveNeeded(true);
        }
      } else if (chosenPayment.native) {
        setApproveNeeded(false);
      }
    };
    getApproveInfo();
  }, [chosenPayment, firstinput, isConfirmed]);

  useEffect(() => {
    async function getWeiRaised1() {
      const rate = await getWeiRaised();
      settotalRaised(rate);
      const getTokens = await getTokenAmount(
        info.usdtAddress,
        addDecimal(1, 18)
      );
      const usdt = 1 / Number(getTokens).toFixed(0);
      setusdtPrice(usdt.toFixed(4));
      const hardc = await getHardcap();
      const tokensSold = await getTokensSold();
      sethardcap(hardc);
      settokensSold(tokensSold);
      setBarwidth((Number(tokensSold) / Number(hardc)) * 100);
    }
    getWeiRaised1();
  }, [isConfirmed]);

  useEffect(() => {
    const getuserinfo = async () => {
      if (address && isConnected) {
        const userbuyamount = await getUsersBought(address);
        console.log(userbuyamount, "usersBuy");
        setusersBuy(userbuyamount);
      }
    };
    getuserinfo();
  }, [address, isConnected, isConfirmed]);

  useEffect(() => {
    const calculateTokens = async () => {
      if (!firstinput || firstinput == 0) {
        setsecondinput(0);
      } else {
        const getTokens = await getTokenAmount(
          chosenPayment.address,
          addDecimal(firstinput, 18, 0)
        );
        setsecondinput(getTokens);
      }
    };
    calculateTokens();
  }, [firstinput, chosenPayment]);

  const approveTx = async () => {
    try {
      writeContract({
        abi: erc20Abi,
        address: chosenPayment.address,
        functionName: "approve",
        args: [info.contractAddress, addDecimal(firstinput, 18)],
      });
    } catch (error) {
      toast.error("Error: Transaction couldn't sent to the blockchain");
    }
  };

  const processTx = async () => {
    if (approveNeeded) {
      approveTx();
    } else {
      if (chosenPayment.native) {
        writeContract({
          abi: presaleAbi,
          address: info.contractAddress,
          functionName: "buyWithBnb",
          args: [addDecimal(firstinput, 18)],
          value: addDecimal(firstinput, 18),
        });
      } else {
        writeContract({
          abi: presaleAbi,
          address: info.contractAddress,
          functionName: "buyTokens",
          args: [addDecimal(firstinput, 18), chosenPayment.address],
        });
      }
    }
  };

  return (
    <>
      <Navbar
        expand="lg"
        className="bg-body-tertiary"
        style={{
          backgroundImage:
            "linear-gradient(0deg, rgb(35, 10, 61), rgb(255, 255, 255))",
        }}
      >
        <Container fluid>
          <Navbar.Brand>Follow us on social media now :</Navbar.Brand>
          <div className="social-icons">
            <a
              href="https://x.com/violetaitoken?s=21&t=wYQW2ocebcJCM1jpUtzXEA"
              title="Twitter"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon
                icon={faTwitter}
                style={{
                  fontSize: "22px",
                  color: "black",
                  marginLeft: "10px",
                  marginRight: "10px",
                }}
              />
            </a>
            <a
              href="https://t.me/+dnlHMz7cEV0xYjRk"
              title="Telegram"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon
                icon={faTelegram}
                style={{
                  fontSize: "22px",
                  color: "black",
                  marginLeft: "10px",
                  marginRight: "10px",
                }}
              />
            </a>
            <a
              href="https://www.instagram.com/violet.ai22?igsh=eDl2bG1vcjNmYWY1"
              title="Instagram"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon
                icon={faInstagram}
                style={{
                  fontSize: "22px",
                  color: "black",
                  marginLeft: "10px",
                  marginRight: "10px",
                }}
              />
            </a>
          </div>
          <Navbar.Toggle
            aria-controls="navbarScroll"
            className="justify-content-end"
          />
          <Navbar.Collapse id="navbarScroll">
            <Nav className="ms-auto my-2 my-lg-0" navbarScroll>
              <Nav.Link href="/">HOME</Nav.Link>
              <Nav.Link href="https://violetai.online/">WEBSITE</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <div className="container mt-5 mb-5 position-relative">
        <div className="row pb-5">
          <div
            className="col-md-6"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: `${height - 240}px`,
            }}
          >
            <Card style={{ maxWidth: "300px" }}>
              <Card.Body>
                <ProgressBar
                  striped
                  animated={true}
                  variant="warning"
                  now={Number(barwidth)}
                />
                <h5 className="text-small mt-2 text-center">
                  <span className="bold-text"> USDT RAISED:</span>{" "}
                  {"$" + totalRaised}
                </h5>
                <h5 className="text-small mt-2 text-center">
                  <span className="bold-text"> TOKENS SOLD:</span>{" "}
                  {Number(tokensSold).toFixed(0) + "/" + hardcap}
                </h5>

                <h5 className="text-small text-center">
                  {
                    <>
                      <span className="bold-text">
                        YOUR PURCHASED {info.buyToken.symbol} =
                      </span>{" "}
                      <>{!isConnected ? "0" : usersBuy}</>
                    </>
                  }
                </h5>
                <div className="row mt-2">
                  <div className="col-3">
                    <hr
                      className="lined"
                      style={{ float: "left", width: "100%" }}
                    />
                  </div>
                  <div className="col-6  text-center">
                    <h5
                      className="text-small mt-2"
                      style={{ fontSize: "18px" }}
                    >
                      {" "}
                      1 {info.buyToken.symbol} = ${usdtPrice}
                    </h5>
                  </div>
                  <div className="col-3">
                    <hr
                      className="lined"
                      style={{ float: "right", width: "100%" }}
                    />
                  </div>
                </div>
                <div className="row mt-2 mb-3">
                  {info.tokens.map((e) => (
                    <div
                      className="col-6"
                      onClick={() => setchosenPayment(e)}
                      key={e.symbol}
                    >
                      <Currency token={e} chosenPayment={chosenPayment} />
                    </div>
                  ))}
                </div>
                <div className="row mt-3">
                  <div className="col-12">
                    <p className="text-small" style={{ fontWeight: "bold" }}>
                      Pay with {chosenPayment && chosenPayment.symbol}
                    </p>
                    <Input
                      token={chosenPayment}
                      value={firstinput}
                      setValue={setfirstinput}
                    />
                  </div>
                  <div className="col-12 mt-3">
                    <p className="text-small" style={{ fontWeight: "bold" }}>
                      Receive {info.buyToken.symbol}{" "}
                    </p>
                    <Input
                      token={info.buyToken}
                      disabled={true}
                      value={secondinput}
                      setValue={setsecondinput}
                    />
                  </div>
                  <div className="col-12 mt-3">
                    {!isConnected && (
                      <ConnectKitButton.Custom>
                        {({ show, hide, address, ensName, chain }) => {
                          return (
                            <div className="connect-buy-button" onClick={show}>
                              CONNECT WALLET
                            </div>
                          );
                        }}
                      </ConnectKitButton.Custom>
                    )}
                    {address && isConnected && !isConfirming && (
                      <div className="connect-buy-button" onClick={processTx}>
                        {approveNeeded
                          ? "APPROVE"
                          : "BUY " + info.buyToken.symbol}
                      </div>
                    )}
                    {isConfirming && (
                      <div className="connect-buy-button">
                        <Spinner />
                      </div>
                    )}
                  </div>
                </div>
              </Card.Body>
            </Card>
          </div>
          <div className="col-md-6 text-center mt-3 mb-3">
            <h5
              style={{
                color: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              We want you to know Investing in our token during the presale
              phase offers numerous benefits that you won’t want to miss.
            </h5>
          </div>
        </div>
      </div>

      <div
        className="fluid"
        style={{
          margin: "0",
          padding: "0",
          position: "fixed",
          bottom: "0",
          width: "100%",
        }}
      >
        <div
          className="row align-items-center justify-content-center"
          style={{
            backgroundImage:
              "linear-gradient(0deg, rgb(0, 47, 71), rgb(255, 255, 255))",
            width: "100%",
            height: "70px",
            margin: "0",
            padding: "0",
          }}
        >
          <div className="c-contact">
            <div className="text-end">
              <span>Follow us on social media now : </span>
              <span>
                <a
                  href="https://x.com/violetaitoken?s=21&t=wYQW2ocebcJCM1jpUtzXEA"
                  title="Twitter"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon
                    icon={faTwitter}
                    style={{
                      fontSize: "22px",
                      color: "black",
                      marginLeft: "10px ",
                      marginRight: "10px",
                    }}
                  />
                </a>
                <a
                  href="https://t.me/+dnlHMz7cEV0xYjRk"
                  title="Telegram"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon
                    icon={faTelegram}
                    style={{
                      fontSize: "22px",
                      color: "black",
                      marginLeft: "10px ",
                      marginRight: "10px",
                    }}
                  />
                </a>
                <a
                  href="https://www.instagram.com/violet.ai22?igsh=eDl2bG1vcjNmYWY1"
                  title="Instagram"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon
                    icon={faInstagram}
                    style={{
                      fontSize: "22px",
                      color: "black",
                      marginLeft: "10px ",
                      marginRight: "10px",
                    }}
                  />
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Presale;

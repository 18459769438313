import React from "react";
import info from "../info";

function Input({ token, value, setValue, disabled }) {
  return (
    <div className="token-input">
      <div className="row">
        <div className="col-10">
          <input
            type="number"
            className="token-input-input"
            disabled={disabled}
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
        </div>
        <div
          className="col-2"
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <img
            src={token.image}
            alt=""
            className="token-image"
            style={{
              width: "40px",
              borderRadius:  "50%",
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default Input;
